import { getStoredAuthState } from '@plexxis/react-auth-provider';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const importsUrl: string = import.meta.env.VITE_API_URL || '__VITE_API_URL__';

export const emptySplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.location.protocol}//${importsUrl}`,
    prepareHeaders: (headers) => {
      const authState = getStoredAuthState();
      if (authState.status == 'loggedIn') {
        headers.set('Authorization', `Bearer ${authState.loginDetails.accessToken}`);
      }

      return headers;
    },
  }),
  reducerPath: 'importsApiReducer',
  endpoints: () => ({}), // Define your endpoints here.
});
